import React from "react"
import ReactDOM from "react-dom"
import {BrowserRouter as Router} from "react-router-dom"
import App from "./app"
import CssBaseline from "@material-ui/core/CssBaseline"
import ScrollToTop from "components/scroll-to-top"
import * as serviceWorker from "./service-worker";

const rootElement = document.getElementById("root")
ReactDOM.render(
  <>
    <Router>
      <ScrollToTop />
      <CssBaseline />
      <App />
    </Router>
  </>,
  rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
