import React from "react"
import {Route, Redirect} from "react-router-dom"
import {route} from "modules/routing/route"
import useCurrentUser from "hooks/useCurrentUser"
import {ACCOUNT_TYPES} from "modules/auth/account-types"

const AuthenticatedRoute = ({children, path, exact, component: Component, type}) => {
  const user = useCurrentUser()
  if (user.isLoading) {
    return null
  }
  if (!user.isLoggedIn) {
    return <Redirect to={route.login} />
  }
  if (type === "admin" && user.role !== ACCOUNT_TYPES.Admin) {
    return <Redirect to={route.home} />
  }
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => <Component {...props} user={user} />}
    >
      {children}
    </Route>
  )
}

export default AuthenticatedRoute
