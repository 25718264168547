import React from "react"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import useCurrentUser from "hooks/useCurrentUser"
import {makeStyles} from "@material-ui/core/styles"
import {withRouter} from "react-router-dom"
import {route} from "modules/routing/route"
import {ACCOUNT_TYPES} from "modules/auth/account-types"
import accountSettingsIcon from "./account-settings-icon.png"
import myBusinessesIcon from "./my-businesses-icon.png"
import exampleDealsIcon from "./example-deals-icon.png"
import referMerchantIcon from "./refer-merchant-icon.png"
import signInOutIcon from "./sign-in-out-icon.png"
import homeIcon from "./home-icon.png"
import faqIcon from "./faq-icon.png"
import contactIcon from "./contact-icon.png"

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    width: "50px",
    height: "50px",
    zIndex: 99,
    position: "absolute",
    right: 10,
    top: 20,
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  drawerPaper: {
    width: drawerWidth,
  },
}))

const MenuIcon = ({img, alt}) => <img src={img} height="22" width="22" alt={alt} />

const ResponsiveDrawer = (props) => {
  const {history} = props
  const {window} = props
  const classes = useStyles()
  const user = useCurrentUser()
  const handleDrawerToggle = () => {
    props.setMobileDrawerOpen(false)
  }

  let accountNavList = []
  if (user.isLoggedIn) {
    accountNavList = [
      {
        text: "Account Settings",
        icon: <MenuIcon img={accountSettingsIcon} alt={"account settings"} />,
        onClick: () => history.push(route.profile),
      },
      user.role === ACCOUNT_TYPES.Admin
        ? {
            text: "Dashboard",
            icon: <MenuIcon img={myBusinessesIcon} alt={"dashboard"} />,
            onClick: () => history.push(route.dashboard),
          }
        : {
            text: "My Businesses",
            icon: <MenuIcon img={myBusinessesIcon} alt={"my businesses"} />,
            onClick: () => history.push(route.businesses),
          },
      {
        text: "Example Deals",
        icon: <MenuIcon img={exampleDealsIcon} alt={"example deals"} />,
        onClick: () => history.push(route.exampleDeals),
      },
      {
        text: "Refer Merchant",
        icon: <MenuIcon img={referMerchantIcon} alt={"refer merchant"} />,
        onClick: () => history.push(route.referMerchant),
      },
    ]
  }

  const mainNavListLogin = {
    isLoggedIn: {
      text: "Sign Out",
      icon: <MenuIcon img={signInOutIcon} alt={"sign out"} />,
      onClick: () => {
        user.clearUser()
      },
    },
    isNotLoggedIn: {
      text: "Sign In",
      icon: <MenuIcon img={signInOutIcon} alt={"sign in"} />,
      onClick: () => history.push(route.login),
    },
  }

  const authLink = user.isLoggedIn
    ? mainNavListLogin.isLoggedIn
    : mainNavListLogin.isNotLoggedIn

  const mainNavList = [
    {
      text: "Home",
      icon: <MenuIcon img={homeIcon} alt={"home"} />,
      onClick: () => history.push("/"),
    },
    {
      text: "FAQ",
      icon: <MenuIcon img={faqIcon} alt={"frequently asked questions"} />,
      onClick: () => history.push(route.faq),
    },
    {
      text: "Contact Us",
      icon: <MenuIcon img={contactIcon} alt={"contact us"} />,
      onClick: () => history.push(route.contactUs),
    },
    {
      text: authLink.text,
      icon: authLink.icon,
      onClick: authLink.onClick,
    },
  ]

  const drawer = (
    <div>
      {accountNavList.length > 0 ? (
        <>
          <Divider />
          <List>
            {accountNavList.map((item, index) => {
              const {text, icon, onClick: _onClick} = item
              const onClick = () => {
                _onClick()
                props.setMobileDrawerOpen(false)
              }
              return (
                <ListItem key={index} button onClick={onClick}>
                  {icon && <ListItemIcon>{icon}</ListItemIcon>}
                  <ListItemText primary={text} />
                </ListItem>
              )
            })}
          </List>
        </>
      ) : null}
      <Divider />
      <List>
        {mainNavList.map((item, index) => {
          const {text, icon, onClick: _onClick} = item
          const onClick = () => {
            _onClick()
            props.setMobileDrawerOpen(false)
          }
          return (
            <ListItem key={index} button onClick={onClick}>
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={text} />
            </ListItem>
          )
        })}
      </List>
    </div>
  )

  const container = window !== undefined ? () => window().document.body : undefined
  return (
    <div>
      <div className={classes.drawer} aria-label="mailbox folders">
        <Hidden implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor="right"
            open={props.isMobileDrawerOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </div>
    </div>
  )
}

export default withRouter(ResponsiveDrawer)
