import {createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"

const bazingBlue = "#0063a6"

let theme = createMuiTheme({
  card: {
    width: 500,
    minHeight: 300,
    padding: "5%",
  },
  header: {},
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "white",
      },
    },
  },
  palette: {
    primary: {
      main: bazingBlue,
    },
    secondary: {
      main: bazingBlue,
    },
  },
  typography: {
    body1: {
      fontWeight: 500,
      fontSize: "1rem",
    },
    h2: {
      fontWeight: 500,
      fontSize: "2rem",
    },
    h4: {
      color: "black",
      fontSize: ".7em",
      fontWeight: 600,
      paddingBottom: 10,
    },
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: 40,
      },
    },
  },
})

theme.typography.h1 = {
  color: "#fff",
  fontWeight: 500,
  fontSize: 36,
  lineHeight: 1.2,
  margin: 0,
  padding: 0,
  [theme.breakpoints.down("md")]: {
    fontSize: 28,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
  },
}

theme = responsiveFontSizes(theme);

export default theme
