import React, {useState, createRef} from "react"
import {route} from "modules/routing/route"
import {ThemeProvider} from "@material-ui/core/styles"
import theme from "./theme"
import Footer from "components/footer"
import {Route, Switch, Redirect} from "react-router-dom"
import Drawer from "components/drawer"
import {makeStyles} from "@material-ui/core/styles"
import HeaderRoutes from "components/header/header-routes"
import withAppInsights from "./modules/logging/app-insights"
import {SnackbarProvider} from "notistack"
import {useIsPhone} from "hooks/useIsPhone"
import CancelIcon from "@material-ui/icons/Cancel"
import logout from "pages/authentication/logout"
import AuthenticatedRoute from "modules/auth/authenticated-route"
import {CurrentUserProvider} from "hooks/useCurrentUser"
import {Grid} from "@material-ui/core"
import Spinner from "components/spinner"

const useStyles = makeStyles({
  container: {
    display: "flex",
  },
  headerRoutesContainer: {
    flexShrink: 0,
  },
  drawer: (props) => {},
  routes: {flexGrow: 1},
  content: (props) => {
    return {
      width: "100%",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
    }
  },
  footer: {
    flexShrink: 0,
  },
})

const Suspense = ({fallback, children}) => {
  return (
    <React.Suspense
      fallback={
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <Spinner />
          </Grid>
        </Grid>
      }
    >
      {children}
    </React.Suspense>
  )
}

const pages = {
  Authentication: "./pages/authentication",
  Profile: "./pages/profile",
  ChangePassword: "./pages/change-password",
  Faq: "./pages/faq",
  ContactUs: "./pages/contact-us",
  DealsAll: "./pages/deals/all",
  DealEdit: "./pages/deals/edit",
  DealCreate: "./pages/deals/create",
  UserCreate: "./pages/admin/users/create",
  UsersAll: "./pages/admin/users/all",
  Dashboard: "./pages/dashboard",
  LocationsAll: "./pages/locations/all",
  LocationEdit: "./pages/locations/edit",
  LocationCreate: "./pages/locations/create",
  ExampleDeals: "./pages/example-deals",
  Register: "./pages/admin/register",
  NotFound: "./pages/404",
  BusinessCreate: "./pages/businesses/create",
  BusinessEdit: "./pages/businesses/edit",
  Businesses: "./pages/businesses/all",
  BusinessDetails: "./pages/businesses/details",
  AdminUserAdd: "./pages/admin/businesses/users/add",
  ReferMerchant: "./pages/refer-merchant",
  AdminBusinesses: "./pages/admin/businesses/all",
  AdminDeals: "./pages/admin/deals",
  AdminUserEdit: "./pages/admin/users/edit",
  AdminUserBusinesses: "./pages/admin/users/businesses",
  ForgotPassword: "./pages/forgot-password",
  Home: "./pages/home",
}

const PageComponents = Object.keys(pages).reduce((acc, key) => {
  const Component = React.lazy(() => import(`${pages[key]}`))
  acc[key] = (props) => {
    return (
      <Suspense>
        <Component {...props} />
      </Suspense>
    )
  }
  return acc
}, {})

const App = () => {
  const isPhone = useIsPhone()
  theme.isPhone = isPhone
  const [isMobileDrawerOpen, setMobileDrawerOpen] = useState(false)
  const classes = useStyles({isPhone})
  const notistackRef = createRef()
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <ThemeProvider theme={theme}>
      <CurrentUserProvider>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          autoHideDuration={3000}
          maxSnack={3}
          ref={notistackRef}
          action={(key) => <CancelIcon onClick={onClickDismiss(key)} />}
        >
          <div className={classes.container}>
            <div className={classes.drawer}>
              <Drawer
                isMobileDrawerOpen={isMobileDrawerOpen}
                setMobileDrawerOpen={setMobileDrawerOpen}
                logout={logout}
              />
            </div>
            <div className={classes.content}>
              <div className={classes.headerRoutesContainer}>
                <HeaderRoutes
                  isMobileDrawerOpen={isMobileDrawerOpen}
                  setMobileDrawerOpen={setMobileDrawerOpen}
                />
              </div>
              <div className={classes.routes}>
                <Switch>
                  <Route path={route.home} component={PageComponents.Home} exact />
                  <Route path={route.logout} component={PageComponents.Home} exact />
                  <Route path={route.faq} component={PageComponents.Faq} exact />
                  <Route
                    path={route.contactUs}
                    component={PageComponents.ContactUs}
                    exact
                  />
                  <AuthenticatedRoute
                    path={route.exampleDeals}
                    component={PageComponents.ExampleDeals}
                    exact
                  />
                  <Route
                    path={route.login}
                    component={PageComponents.Authentication}
                    exact
                  />
                  <Route
                    path={route.forgotPassword}
                    component={PageComponents.ForgotPassword}
                    exact
                  />
                  <AuthenticatedRoute
                    type="admin"
                    path={route.userEdit}
                    component={PageComponents.AdminUserEdit}
                    exact
                  />
                  <AuthenticatedRoute
                    type="admin"
                    path={route.register}
                    component={PageComponents.Register}
                  />
                  <AuthenticatedRoute
                    type="admin"
                    path={route.createUser}
                    component={PageComponents.UserCreate}
                    exact
                  />
                  <AuthenticatedRoute
                    type="admin"
                    path={route.allUsers}
                    component={PageComponents.UsersAll}
                    exact
                  />
                  <AuthenticatedRoute
                    type="admin"
                    path={route.dashboard}
                    component={PageComponents.Dashboard}
                    exact
                  />
                  <AuthenticatedRoute
                    path={route.changePassword}
                    component={PageComponents.ChangePassword}
                    exact
                  />
                  <AuthenticatedRoute
                    path={route.referMerchant}
                    component={PageComponents.ReferMerchant}
                    exact
                  />
                  <AuthenticatedRoute
                    path={route.profile}
                    component={PageComponents.Profile}
                    exact
                  />
                  <AuthenticatedRoute
                    path={route.businesses}
                    component={PageComponents.Businesses}
                    exact
                  />
                  <AuthenticatedRoute
                    type="admin"
                    path={route.businessCreate}
                    component={PageComponents.BusinessCreate}
                    exact
                  />
                  <AuthenticatedRoute
                    path={route.businessDetails}
                    component={PageComponents.BusinessDetails}
                    exact
                  />
                  <AuthenticatedRoute
                    path={route.businessEdit}
                    component={PageComponents.BusinessEdit}
                    exact
                  />
                  <AuthenticatedRoute
                    type="admin"
                    path={route.businessUsers}
                    component={PageComponents.AdminUserAdd}
                    exact
                  />
                  <AuthenticatedRoute
                    path={route.locations}
                    component={PageComponents.LocationsAll}
                    exact
                  />
                  <AuthenticatedRoute
                    type="admin"
                    path={route.userBusinesses}
                    component={PageComponents.AdminUserBusinesses}
                    exact
                  />
                  <AuthenticatedRoute
                    type="admin"
                    path={route.adminDeals}
                    component={PageComponents.AdminDeals}
                    exact
                  />
                  <AuthenticatedRoute
                    path={route.locationsEdit}
                    component={PageComponents.LocationEdit}
                    exact
                  />
                  <AuthenticatedRoute
                    path={route.locationsCreate}
                    component={PageComponents.LocationCreate}
                    exact
                  />
                  <AuthenticatedRoute
                    path={route.deals}
                    component={PageComponents.DealsAll}
                    exact
                  />
                  <AuthenticatedRoute
                    path={route.dealsCreate}
                    component={PageComponents.DealCreate}
                    exact
                  />
                  <AuthenticatedRoute
                    path={route.dealsEdit}
                    component={PageComponents.DealEdit}
                    exact
                  />

                  <AuthenticatedRoute
                    type="admin"
                    path={route.adminBusinesses}
                    component={PageComponents.AdminBusinesses}
                    exact
                  />

                  <Route path={route.notFound} component={PageComponents.NotFound} />
                  <Redirect to={route.notFound} />
                </Switch>
              </div>
              <div className={classes.footer}>
                <Footer />
              </div>
            </div>
          </div>
        </SnackbarProvider>
      </CurrentUserProvider>
    </ThemeProvider>
  )
}

export default withAppInsights(App)
