import React, {useEffect, useReducer} from "react"
import useLocalStorage from "hooks/useLocalStorage"
import jwtDecode from "jwt-decode"
import {appInsights} from "modules/logging/app-insights"
import {SeverityLevel} from "@microsoft/applicationinsights-web"

const initialState = {
  isLoading: true,
  isLoggedIn: false,
  isAdmin: false,
  currentUser: null,

  type: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return {...state, isLoading: true}
    case "SET_AUTHORIZED":
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        ...action.payload,
      }
    case "SET_UNAUTHORIZED":
      return {
        ...state,
        unique_name: "",
        isLoggedIn: false,
        isLoading: false,
        role: null,
      }
    default:
      return state
  }
}
const CurrentUserContext = React.createContext({})
export function CurrentUserProvider({children}) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [token, setToken] = useLocalStorage("token")

  useEffect(() => {
    if (token) {
      try {
        const payload = jwtDecode(token)
        dispatch({type: "SET_AUTHORIZED", payload})
      } catch (error) {
        appInsights.trackException({
          error: new Error(error),
          severityLevel: SeverityLevel.Error,
        })
        setToken("")
        dispatch({type: "SET_UNAUTHORIZED"})
      }
    } else {
      dispatch({type: "SET_UNAUTHORIZED"})
    }
  }, [token, setToken])
  let value = state
  value.clearUser = () => {
    dispatch({type: "SET_UNAUTHORIZED"})
    setToken("")
  }
  value.setToken = setToken
  return (
    <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>
  )
}
export default function useCurrentUser() {
  return React.useContext(CurrentUserContext)
}
