export const route = {
  home: "/",
  login: "/login",
  logout: "/logout",
  forgotPassword: "/forgot-password",
  changePassword: "/change-password",
  profile: "/profile",
  contactUs: "/contact-us",
  faq: "/faq",
  exampleDeals: "/example-deals",
  referMerchant: "/refer-merchant",
  businesses: "/businesses",
  businessCreate: "/businesses/create",
  businessEdit: "/businesses/:businessId/edit",
  businessDetails: "/businesses/:businessId",
  deals: "/businesses/:businessId/deals",
  dealsCreate: "/businesses/:businessId/deals/create",
  dealsEdit: "/businesses/:businessId/deals/:dealId/edit",
  locations: "/businesses/:businessId/locations",
  locationsCreate: "/businesses/:businessId/locations/create",
  locationsEdit: "/businesses/:businessId/locations/:locationId/edit",
  adminBusinesses: "/admin/businesses/all",
  adminDeals: "/admin/deals",
  businessUsers: "/admin/businesses/:businessId/users/add",
  register: "/admin/register",
  registerUser: "/admin/register/user",
  registerBusiness: "/admin/register/user/:userId/business",
  registerLocation: "/admin/register/user/:userId/business/:businessId/location",
  registerDeal:
    "/admin/register/user/:userId/business/:businessId/location/:locationId/deal",
  dashboard: "/admin/dashboard",
  allUsers: "/admin/users/all",
  userBusinesses: "/admin/users/:userId/businesses",
  userEdit: "/admin/users/:userId/edit",
  createUser: "/admin/user/create",
  notFound: "/404",
}
