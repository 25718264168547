import {ApplicationInsights} from "@microsoft/applicationinsights-web"
import {ReactPlugin, withAITracking} from "@microsoft/applicationinsights-react-js"
import {createBrowserHistory} from "history"

const browserHistory = createBrowserHistory({basename: ""})
const getConfig = () => {
  const config = {
    "localhost:3000": {
      instrumentationKey: "",
    },
    "app-bazing-merchant-dev-eastus-001.azurewebsites.net": {
      instrumentationKey: "be01a10a-f7e0-456d-b82f-4842bcf5b2a4",
    },
    "merchant.dev.bazing.com": {
      instrumentationKey: "be01a10a-f7e0-456d-b82f-4842bcf5b2a4",
    },
    "app-bazing-merchant-prod-eastus-001.azurewebsites.net": {
      instrumentationKey: "18e2d7f5-72db-421c-ae09-3efc402e6ff2",
    },
    "app-bazing-merchant-prod-eastus-001-staging.azurewebsites.net": {
      instrumentationKey: "18e2d7f5-72db-421c-ae09-3efc402e6ff2",
    },
    "merchant.staging.bazing.com": {
      instrumentationKey: "18e2d7f5-72db-421c-ae09-3efc402e6ff2",
    },
    "merchant.prod.bazing.com": {
      instrumentationKey: "18e2d7f5-72db-421c-ae09-3efc402e6ff2",
    },    
    "merchant.bazing.com": {
      instrumentationKey: "18e2d7f5-72db-421c-ae09-3efc402e6ff2",
    },
  }
  return config[window.location.host]
}

var reactPlugin = new ReactPlugin()
var ai = new ApplicationInsights({
  config: {
    instrumentationKey: getConfig().instrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {history: browserHistory},
    },
  },
})
ai.loadAppInsights()

export default (Component) => withAITracking(reactPlugin, Component)
export const appInsights = ai.appInsights
