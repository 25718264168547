import React, {useState} from "react"
import {Switch, Route, NavLink, Link, useLocation, useHistory} from "react-router-dom"
import brandLogo from "./bazing-white-logo.png"
import blackBrandLogo from "./bazing-black-logo.png"
import useCurrentUser from "hooks/useCurrentUser"
import {makeStyles, styled, useTheme} from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import {Grid, Tabs, Tab, Menu as MuiMenu, MenuItem, Button} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import {ACCOUNT_TYPES} from "modules/auth/account-types"
import {route} from "modules/routing/route"

const useStyles = makeStyles((theme) => ({
  menuItemList: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center",
    listStyleType: "none",
    maxWidth: 800,
  },
  closeIcon: {
    color: "white",
    fontSize: "50px",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  tab: {
    color: "white",
    fontSize: "1.2em",
  },
}))

const Menu = styled("nav")((theme) => ({
  padding: 24,
}))

const MenuDark = styled(Menu)({
  borderBottom: "2px solid #bbb",
})

const MenuLight = styled(Menu)({
  width: "100%",
  position: "absolute",
  zIndex: 11,
})

const BrandLogo = styled("img")(({theme}) => ({
  display: "block",
  width: 300,
  [theme.breakpoints.down("md")]: {
    width: 250,
  },
  [theme.breakpoints.down("xs")]: {
    width: 200,
  },
}))

const IconContainer = styled("div")({
  width: "50px",
  height: "50px",
  zIndex: 99,
  marginLeft: "16px",
})

const StyledMenuIcon = styled(MenuIcon)({
  fontSize: "50px",
})

const HeaderRoutes = (props) => {
  return (
    <Switch>
      <Route
        path={[
          route.home,
          route.faq,
          route.contactUs,
          route.exampleDeals,
          route.referMerchant,
          route.businesses,
        ]}
        render={(history) => <Header {...props} mode="light" history={history} />}
        exact
      />
      <Route render={(history) => <Header {...props} mode="dark" history={history} />} />
    </Switch>
  )
}

const Header = ({mode = "dark", ...restProps}) => {
  const location = useLocation()
  const muiTheme = useTheme()
  const history = useHistory()
  const isMobile = useMediaQuery(muiTheme.breakpoints.down("md"))
  const Holder = mode === "light" ? MenuLight : MenuDark
  const user = useCurrentUser()

  const getLinkColor = () => {
    if (mode === "dark") {
      return "black"
    }
    return "white"
  }

  const styles = {
    color: getLinkColor(),
  }

  const classes = useStyles()
  const main =
    user.isLoggedIn && user.role === ACCOUNT_TYPES.Admin
      ? {
          label: "Dashboard",
          route: route.dashboard,
        }
      : {label: "My Account"}

  const [anchorEl, setAnchorEl] = useState(null)

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget)
  //}

  const handleClose = () => {
    setAnchorEl(null)
  }

  const mapping = {
    loggedIn: {
      [main.route]: {
        tabValue: 0,
        label: main.label,
        onClick: (event) => setAnchorEl(event.currentTarget),
        Component: Button,
      },
      [route.faq]: {tabValue: 1, label: "FAQ"},
      [route.contactUs]: {tabValue: 2, label: "Contact Us"},
      [route.logout]: {
        tabValue: 3,
        label: "Sign Out",
        onClick: (e) => {
          e.preventDefault()
          user.clearUser()
        },
      },
    },
    notLoggedIn: {
      [route.home]: {tabValue: 0, label: "Home"},
      [route.faq]: {tabValue: 1, label: "FAQ"},
      [route.contactUs]: {tabValue: 2, label: "Contact Us"},
      [route.login]: {tabValue: 3, label: "Sign In"},
    },
  }

  let routes = {}

  if (typeof user.isLoggedIn === "boolean") {
    routes = user.isLoggedIn ? mapping.loggedIn : mapping.notLoggedIn
  }
  const tabStyle = {color: mode === "dark" ? "black" : "white", opacity: 0.9}
  const navigate = (route) => () => {
    setAnchorEl(null)
    history.push(route)
  }
  return (
    <>
      <MuiMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {user.role === ACCOUNT_TYPES.Admin ? (
          <div>
            <MenuItem onClick={navigate(route.dashboard)}>Dashboard</MenuItem>
            <MenuItem onClick={navigate(route.adminBusinesses)}>Businesses</MenuItem>
            <MenuItem onClick={navigate(route.adminDeals)}>Deals</MenuItem>
            <MenuItem onClick={navigate(route.allUsers)}>Users</MenuItem>
            <MenuItem onClick={navigate(route.profile)}>Account Settings</MenuItem>
            <MenuItem onClick={navigate('/admin/register/business')}>New Merchant</MenuItem>
          </div>
        ) : (
          <div>
            <MenuItem onClick={navigate(route.profile)}>Account Settings</MenuItem>
            <MenuItem onClick={navigate(route.businesses)}>My Businesses</MenuItem>
            <MenuItem onClick={navigate(route.exampleDeals)}>See Example Deals</MenuItem>
          </div>
        )}
      </MuiMenu>

      <Holder className={classes.header}>
        <Grid container justify="space-between" alignItems="center" direction="row">
          <Link to="/">
            <BrandLogo src={mode === "dark" ? blackBrandLogo : brandLogo} />
          </Link>
          <Grid item>
            {isMobile ? (
              <IconContainer onClick={() => restProps.setMobileDrawerOpen(true)}>
                <StyledMenuIcon style={{color: mode === "dark" ? "black" : "white"}} />
              </IconContainer>
            ) : (
              <Grid container>
                <Grid item>
                  <Tabs
                    TabIndicatorProps={{
                      style: {background: mode === "dark" ? "black" : "white"},
                    }}
                    className={classes.menuItemList}
                    value={routes[location.pathname]?.tabValue ?? 0}
                  >
                    {Object.entries(routes).map(([routeName, value]) => {
                      return (
                        <Tab
                          style={{...tabStyle, ...styles}}
                          key={routeName}
                          label={value.label}
                          component={value.Component || NavLink}
                          onClick={value.onClick}
                          to={routeName}
                          className={classes.tab}
                        />
                      )
                    })}
                  </Tabs>
                </Grid>
                <Grid item>
                  <IconContainer onClick={() => restProps.setMobileDrawerOpen(true)}>
                    <StyledMenuIcon
                      style={{color: mode === "dark" ? "black" : "white"}}
                    />
                  </IconContainer>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Holder>
    </>
  )
}

export default HeaderRoutes
