import React from "react"
import {styled} from "@material-ui/core/styles"
import {Link} from "react-router-dom"
import bazingIcon from "./bazing-icon.png"
import facebookIcon from "assets/facebook-icon.png"
import instagramIcon from "assets/instagram-icon.png"
import twitterIcon from "assets/twitter-icon.png"
import linkedinIcon from "assets/linkedin-icon.png"
import useCurrentUser from "hooks/useCurrentUser"
import {ACCOUNT_TYPES} from "modules/auth/account-types"
import {route} from "modules/routing/route"

const FooterContainer = styled("footer")(({theme}) => ({
  backgroundColor: "#000",
  padding: "60px 75px",
  [theme.breakpoints.down("md")]: {
    padding: 0,
  },
}))

const FooterInner = styled("div")(({theme}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}))

const IconHolder = styled("ul")(({theme}) => ({
  listStyle: "none",
  flexGrow: 1,
  display: "flex",
  [theme.breakpoints.down("md")]: {
    padding: "25px",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
  },
}))

const IconImage = styled("img")({
  height: "60px",
  marginRight: 5,
})

const IconBazingImage = styled(IconImage)(({theme}) => ({
  marginRight: 100,
  [theme.breakpoints.down("md")]: {
    margin: 0,
  },
}))

const LinkHolder = styled("ul")(({theme}) => ({
  display: "flex",
  marginRight: "40px",
  flexDirection: "column",
  justifyContent: "flex-start",
  paddingLeft: "40px",
  height: "100%",
  marginTop: "15px",
  borderLeft: "2px solid white",

  "& + ul": {
    borderLeft: "none",
  },

  [theme.breakpoints.down("md")]: {
    borderLeft: "none",
  },
}))

const FooterLink = styled(Link)(({theme}) => ({
  color: "#fff",
  textDecoration: "none",
  marginBottom: 15,
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
}))

const BazingIcon = ({to, img, alt}) => (
  <Link to={to}>
    <IconBazingImage src={img} alt={alt} />
  </Link>
)

const SocialIcon = ({to, img, alt}) => (
  <a href={to} target="_blank" rel="noopener noreferrer">
    <IconImage src={img} alt={alt} />
  </a>
)

const Footer = () => {
  const user = useCurrentUser()

  return (
    <FooterContainer>
      <FooterInner>
        <IconHolder>
          <BazingIcon to="/" img={bazingIcon} alt="BaZing" />
          <SocialIcon
            to="https://www.facebook.com/BaZingDeals"
            img={facebookIcon}
            alt="Facebook"
          />
          <SocialIcon
            to="https://www.instagram.com/bazingdeals"
            img={instagramIcon}
            alt="Instagram"
          />
          <SocialIcon
            to="https://twitter.com/BaZingDeals"
            img={twitterIcon}
            alt="Twitter"
          />
          <SocialIcon
            to="https://www.linkedin.com/showcase/bazingdeals"
            img={linkedinIcon}
            alt="Linkedin"
          />
        </IconHolder>

        {user && user.isLoggedIn && (
          <LinkHolder>
            <FooterLink to={route.profile}>Account Settings</FooterLink>
            {user.role === ACCOUNT_TYPES.Admin ? (
              <FooterLink to={route.adminBusinesses}>Businesses</FooterLink>
            ) : (
              <FooterLink to={route.businesses}>My Businesses</FooterLink>
            )}
            <FooterLink to={route.referMerchant}>Refer A Merchant</FooterLink>
           
          </LinkHolder>
        )}

        <LinkHolder>
          <FooterLink to={route.faq}>FAQ</FooterLink>
          <FooterLink to={route.contactUs}>Contact Us</FooterLink>
          {user?.isLoggedIn && <FooterLink to={route.exampleDeals}>See Example Deals</FooterLink>}
        </LinkHolder>
      </FooterInner>
    </FooterContainer>
  )
}

export default Footer
